import React, { Component } from 'react';
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby"
import _ from 'lodash';

import styles from './galleryFooter.module.scss';

/*
  Adapted from:
  https://blog.usejournal.com/building-highly-performant-masonry-layouts-with-gatsby-js-54115acc3e72
*/

const ITEMS_PER_ROW = 5;
const ROWS_TO_SHOW = 4;

export default class galleryFooter extends Component {
  state = {
    isClient: false,
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  render() {
    if (!this.state.isClient) {
      // Since sizing is baked into the dom & images are shuffled, we need to kill
      // the SSR by ditching out of the initial render
      return null;
    }

    return (
      <StaticQuery
        query={graphql`
          query ImagesQuery {
            allFile(filter: {relativeDirectory: {eq: "friendz"}}) {
              edges {
                node {
                  childImageSharp {
                    fluid {
                      aspectRatio
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <section className={styles.wrapper}>
            <Gallery
              itemsPerRow={ITEMS_PER_ROW}
              images={_.shuffle(data.allFile.edges).map(({ node }) => ({
                ...node.childImageSharp.fluid,
              }))}
            />
          </section>
        )}
      />
    )
  };
}


const Gallery = ({ images, itemsPerRow }) => {
  // Split images into groups of the given size
  const rows = _.chunk(images, itemsPerRow);

  return (
    <section className={styles.row}>
      {rows.splice(0, ROWS_TO_SHOW).map(row => {
        // Sum aspect ratios of images in the given row
        const rowAspectRatioSum = _.sum(row.map(image => image.aspectRatio));

        return row.map(image => (
            <Img
              key={image.src}
              fluid={image}
              className={styles.img}
              style={{
                width: `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
              }}
            />
        ));
      })}
    </section>
  );
};