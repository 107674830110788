import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";
import Text from "../text";
import CenteredColumn from '../centeredColumn';
import ImageCard from '../imageCard';

import styles from './location.module.scss';
import farmImage from '../../images/farm.jpg';
import CreekImage from '../../images/creekJJ.jpg';

export default () => (
  <Fragment>
    <SectionHeader
      title="Location"
      bgColor="bgBurntYellow"
      includeTopBorder
    />

    <section className={styles.marquee}>
      <div className={styles.title}>McCullough Grain</div>
      <div className="h2 mobileOnly" />
      <div className="h5 desktopOnly" />
      <div className={styles.subtitle}>Sharpsville, PA</div>
    </section>

    <Section>
      <Card>
        <CardTitle
          subTitle="History"
          title="A Family Farm"
        />
        <div className="h3 mobileOnly" />
        <div className="h6 desktopOnly" />
        <CenteredColumn>
          <Text className="left">
            <p>
              Established in 1837, the farm has been owned and operated by six generations
              of Justine's family. In 1976, Justine's grandfather Irwin "Speed" McCullough
              bought a small grain dryer, and along with his son, David (Justine's dad),
              founded McCullough Grain. What began as a simple dairy farm has grown into a
              two-location grain operation, with multiple full-time employees, and over
              2,500 acres farmed each year.
            </p>
            <br/>
            <p>
              McCullough Grain grows mostly corn, soybeans, and wheat - as well as some
              sunflowers and a few dozen beef cattle. The property is also home to Gold-N-Grain
              Archery, an indoor/outdoor shooting range and archery supply shop that
              Justine's parents founded in 1991.
            </p>
          </Text>
        </CenteredColumn>
      </Card>
      <ImageCard src={farmImage} />
    </Section>

    <Section>
      <ImageCard src={CreekImage} className={styles.imageFlipMobile} />
      <Card>
        <CardTitle
          subTitle="Legacy"
          title="A Farm Family"
        />
        <div className="h3 mobileOnly" />
        <div className="h6 desktopOnly" />
        <CenteredColumn>
          <Text className="left">
            <p>
              Raised on McCullough Road, Justine spent her childhood shooting archery
              and exploring the fields, forests and creeks around the homestead. With
              her grandparents Charlene and Speed next door, and her sister, Jenna, by
              her side, family was always nearby.
            </p>
            <br/>
            <p>
              We are deeply honored that Justine’s parents, Dave and DeAnn, invited us
              to celebrate our wedding day on the family property. We are excited to
              share this beautiful place with you, and to incorporate you and this day
              with the legacy of the farm.
            </p>
          </Text>
        </CenteredColumn>
      </Card>
    </Section>

    <Section>
      <Card>
        <CardTitle
          subTitle="Venue"
          title="McCullough Grain"
        />
        <div className="h3" />

        <Text useClass="address">
          <address>
            75 McCullough Road<br />
            Sharpsville, PA 16150
          </address>
          <div className="h3 mobileOnly" />
          <div className="h5 desktopOnly" />
          <div className="buttonBlock">
            <a
              className="button"
              href="https://goo.gl/maps/PKC8xDAfUi2iakL29"
              target="_blank"
              rel="noopener noreferrer"
            >
              Map
            </a>
          </div>
        </Text>
        <div className="h5 mobileOnly" />
        <div className="h10 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              The farm is located in a rural area of Western Pennsylvania, about 10
              miles from the Ohio state line.
            </p>
            {/* <br />
            <p>
              Looking for a place to stay that’s near the farm? Keep on scrolling down to the <a href="#travel">Travel</a> Section.
            </p> */}
          </Text>
        </CenteredColumn>
      </Card>

      <iframe
        className="map desktopOnly"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.912137934711!2d-80.38114008432855!3d41.26724891139665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8833beba8ba838ef%3A0xd41e51547714dc48!2s75+McCullough+Rd%2C+Sharpsville%2C+PA+16150!5e0!3m2!1sen!2sus!4v1557370508796!5m2!1sen!2sus"
        frameBorder="0"
        title="farmMap"
        allowFullScreen
      />
    </Section>

    <Section>
      <Card>
        <CardTitle title="Location FAQs" id="locationFAQs" />
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              <strong>Where do I park?</strong><br />
              There is a dedicated area on the farm for parking that will be clearly
              visible from the road on the day of the event.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>When should I show up?</strong><br/>
              The ceremony starts at 5:30pm. We suggest showing up starting at 5pm.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>About that shuttle from the hotel...</strong><br/>
              Information about the shuttle will be available at the hotel on the day of the event. Please plan on being ready to leave the hotel by 4:30pm sharp.
            </p>
          </Text>
        </CenteredColumn>
      </Card>
    </Section>
  </Fragment>
);