import React, { PureComponent, createRef } from "react";
import classNames from 'classnames';

import HeaderLink from './headerLink';

import styles from './header.module.scss';

const LINKS = [
  'Location',
  'About',
  'Schedule',
  // 'To Do',
  'Travel',
  'Registry',
  'FAQS',
  'RSVP',
];

export default class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  state = {
    isOpen: false,
  };

  toggleMenu = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  closeMenu = () => {
    this.setState({isOpen: false});
  }

  makeLink = (link) => {
    return (
      <li className={styles.link} key={link}>
        <HeaderLink
          link={link}
          handleHeaderClose={this.closeMenu}
          headerRef={this.ref}
        />
      </li>
    );
  }

  render() {
    return (
      <header
        className={classNames(styles.header, this.state.isOpen && styles.isOpen)}
        ref={this.ref}
      >
        <button
          className={classNames(styles.hamburgerWrapper, "mobileOnly")}
          onClick={this.toggleMenu}
        >
          <div className={styles.hamburger} />
        </button>

        <ul className={styles.linkTray}>
          { LINKS.map(this.makeLink) }
        </ul>

        <a
          href="https://www.instagram.com/explore/tags/farmaloha/"
          className={classNames(styles.hashtag, "mobileOnly")}
          target="_blank"
          rel="noopener noreferrer"
        >
          #farmaloha
        </a>
      </header>
    );
  };
};
