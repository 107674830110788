import React from 'react';

import styles from './cardTitle.module.scss';

export default ({ title, subTitle, ...rest }) => (
  <section
    className={styles.wrapper}
    {...rest}
  >
    { subTitle &&
      <div className={styles.subTitle}>{subTitle}</div>
    }
    <div className={styles.title}>{title}</div>
  </section>
);
