import React from 'react';
import classNames from 'classnames';

import styles from './card.module.scss';

export default ({ children, className }) => (
  <section
    className={classNames(styles.wrapper, className)}
  >
    <div className={styles.content}>
      {children}
    </div>
  </section>
);
