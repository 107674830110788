import React, { PureComponent } from "react";
import classNames from 'classnames';
import CSSScroll from 'css-scroll';
import _ from 'lodash';

import styles from './header.module.scss';

// Include the border in the calculation
const BORDERS_WIDTH = 3;

export default class Header extends PureComponent {
  state = {
    showComingSoon: false,
  };

  toggleComingSoon = () => {
    this.setState({showComingSoon: !this.state.showComingSoon});
  }

  handleLinkClick = (e, link) => {
    e.preventDefault();
    const target = document.querySelector(link);

    // Bail if target doesn't exist
    if (!target) {
      this.setState({ showComingSoon: true });
      window.setTimeout(() => {
        this.setState({ showComingSoon: false });
      }, 1000);
      return;
    }

    this.props.handleHeaderClose();
    const headerHeight = this.props.headerRef.current.offsetHeight;
    const scrollTo = target.offsetTop - headerHeight - BORDERS_WIDTH;
    CSSScroll(scrollTo, 500, document.querySelector('main'));
  }

  render() {
    const link = this.props.link;
    const linkHref = `#${_.camelCase(link)}`;
    return (
      <a
        href={linkHref}
        onClick={e => this.handleLinkClick(e, linkHref)}
        className={classNames(this.state.showComingSoon && styles.soon)}
      >
        <span className={styles.linkText}>{link}</span>
        <span className={styles.soonText}>Soon!</span>
      </a>
    );
  };
};
