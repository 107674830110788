import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import safeGet from 'lodash.get';

// Adapted from: https://github.com/gatsbyjs/gatsby/issues/10482#issuecomment-485349612

const Image = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: ""}}) {
        edges {
          node {
            publicURL
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(() => (
    data.allFile.edges.find(({ node: { publicURL }}) => src === publicURL)
  ), [ data, src ])

  const fluid = safeGet(match, 'node.childImageSharp.fluid')

  return fluid ? (
    <Img
      fluid={fluid}
      {...props}
    />
  ) : null
};

export default Image;