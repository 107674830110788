import React from 'react';
import classNames from 'classnames';

import styles from './sectionHeader.module.scss';

export default ({ title, bgColor = 'bgWhite', includeTopBorder }) => (
  <section
    className={classNames(styles.sectionHeader, includeTopBorder && styles.includeTopBorder, bgColor)}
    id={title.toLowerCase()}
  >
    {title}
  </section>
);
