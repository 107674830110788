import React from 'react';
import classNames from 'classnames';

import styles from './text.module.scss';

export default ({ children, className, useClass}) => (
  <section
    className={classNames(styles.wrapper, className, styles[useClass])}
  >
    {children}
  </section>
);
