import React from 'react';
import classNames from 'classnames';

import styles from './section.module.scss';

export default ({ children, className }) => (
  <section
    className={classNames(styles.wrapper, className)}
  >
    {children}
  </section>
);
