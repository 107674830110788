import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";
import CenteredColumn from '../centeredColumn';
import Text from "../text";

export default () => (
  <Fragment>
    <SectionHeader
      title="FAQS"
      bgColor="bgBurntYellow"
    />
    <Section>
      <Card>
        <CardTitle
          subTitle="Who What Why"
          title="Frequently Asked Questions"
        />
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              <strong>What's the dress code?</strong><br/>
              Backyard semi-formal. Heels and tie not required, aloha prints encouraged.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>How do I get to the venue? And where do I park?</strong><br/>
              Scroll up a little bit, and check out the <a href="#locationFAQs">Location FAQs section</a>.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>What's the weather going to be like?</strong><br/>
              The current forecast has the high around 77&deg;, with it cooling off after the sun goes down. Bring layers!
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>When does the ceremony start?</strong><br/>
              The ceremony will start at 5:30pm. Parking will be available from 5:00pm.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>What kind of food will be served?</strong><br/>
              <i>Delicious food.</i> To be more specific, we will have meat, vegetarian, and gluten free options.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>Will there be alcohol?</strong><br/>
              Have you met us?<br/> Yes! Plenty of alcohol + mocktail options, and a shuttle bus service to and from the hotel to cover those who wish to imbibe.
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>What's the hashtag for the wedding?</strong><br/>
              It's <strong>#farmaloha</strong>. Aren't we clever?
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>Are there goats on the farm?</strong><br/>
              Nobody has actually asked us this question, but Andy thought it was worth mentioning. And no, there are no goats on the farm. There's quite a few very happy cows, though!
            </p>
            <div className="h6 mobileOnly" />
            <div className="h5 desktopOnly" />
            <p>
              <strong>I have another question that you didn't cover.</strong><br/>
              Text us, or drop us a line: <a href="mailto:lovebirds@farmaloha.com">lovebirds@farmaloha.com</a>
            </p>
          </Text>
        </CenteredColumn>
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
      </Card>
    </Section>
  </Fragment>
);
