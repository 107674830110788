import React from 'react';
import classNames from 'classnames';

import styles from './partyPerson.module.scss';

export default ({
  children,
  image,
  subtitle,
  title,
  flipped = false,
 }) => (
  <article className={classNames(styles.partyPerson, flipped && styles.flipped)}>
    <div className={styles.imageWrapper}>
      <img src={image} className={styles.image} alt="" />
    </div>
    <div className="h3 mobileOnly" />
    <div className={styles.content}>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.title}>{title}</div>
      <div className="h2 mobileOnly" />
      <div className="h5 desktopOnly" />
      <div className={styles.bio}>
        {children}
      </div>
    </div>
  </article>
);
