import React from 'react';

import styles from './marquee.module.scss';

export default () => (
  <section className={styles.marquee}>
    <div className={styles.text}>
      <div>Saturday August 10th 2019
        <span className="desktopOnly"> | </span>
        <br className="mobileOnly" />
        sharpsville, pa
      </div>
      <div className="desktopOnly">
        <a
          href="https://www.instagram.com/explore/tags/farmaloha/"
          className={styles.hashtag}
          target="_blank"
          rel="noopener noreferrer"
        >
          #farmaloha
        </a>
      </div>
    </div>
  </section>
);
