import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";
import Text from "../text";
import CenteredColumn from '../centeredColumn';
import ImageCard from '../imageCard';
import PartyPerson from '../partyPerson';

import styles from './about.module.scss';

import jaAura from '../../images/auraMaster.jpg';
import justineAndy1 from '../../images/JustineAndy1.jpg';
import justineAndy2 from '../../images/JustineAndy2.jpg';
import justineJenna from '../../images/about/JustineJenna.jpg';
import andyJoce from '../../images/about/AndyJoce.jpg';
import justineLexi from '../../images/about/JustineLexi.jpg';
import andyLuke from '../../images/about/AndyLuke.jpg';

export default () => (
  <Fragment>
    <SectionHeader
      title="About"
      bgColor="bgGoldenrod"
    />
    <div className={styles.mobileImageMarquee}>
      <ImageCard src={jaAura} className={styles.mobileImageMarqueeOverride} />
    </div>
    <section className={styles.topWrapper}>
      <div className={styles.leftPic}>
        <ImageCard
          src={justineAndy1}
          className={styles.imageCardOverride}
        />
      </div>
      <Section className={styles.topText}>
        <Card>
          <CardTitle
            subTitle="Justine + Andy"
            title="Farm Aloha"
          />
          <div className="h3 mobileOnly" />
          <div className="h6 desktopOnly" />
          <CenteredColumn>
            <Text className="left">
              <p>
                Somewhere between a text message sent during a long layover and a proposal in the redwoods, we fell in love.
              </p>
              <br />
              <p>
                To us, <strong>Farm Aloha</strong> is more than just a wedding hashtag: it is the marriage of our personalities, captured by a single phrase that evokes love, family, and home.
              </p>
              <br />
              <p>
                We are excited for you to join us in sharing the Aloha Spirit as we celebrate our special day.
              </p>
            </Text>
          </CenteredColumn>
        </Card>
      </Section>
      <div className={styles.rightPic}>
        <ImageCard
          src={justineAndy2}
          className={styles.imageCardOverride}
        />
      </div>
    </section>
    <Section>
      <Card>
        <CardTitle
          subTitle="Special Peeps"
          title="The Wedding Party"
        />
        <div className="h3 mobileOnly" />
        <div className="h6 desktopOnly" />
        <CenteredColumn>
          <Text>
            The important people who will be standing by our sides on our important day, in our own&nbsp;words.
          </Text>
        </CenteredColumn>
        <div className="h7 mobileOnly" />
        <div className="h12 desktopOnly" />
        <section className={styles.partyPeople}>

          <PartyPerson
            subtitle="Sister of Honor"
            title="Jenna Redmond"
            image={justineJenna}
          >
            "Jenna is my sister and my very first friend. We’ve mastered the art of communicating wordlessly, using only facial expressions, and I’m certain no one on the planet shares or even understands our zany sense of humor. We have even more fun together now that her children Felicity and Grant have joined our family. I’m grateful she’s the person in my corner who knows exactly what it was like growing up at 75 McCullough Road." <i>~&nbsp;Justine</i>
          </PartyPerson>

          <div className="h7 mobileOnly" />
          <div className="h12 desktopOnly" />

          <PartyPerson
            subtitle="Best Woman"
            title="Jocelyn Kay Levy"
            image={andyJoce}
            flipped
          >
            "For all her indisputable gifts, the thing I admire most about Jocelyn is her dedication to her friends and family. In the twenty-two years that I've been lucky enough to call her my best friend, she has never once failed to be herself: endlessly caring, insufferably charismatic, and entirely without filter. Throughout life's ups and downs, she has always been there for me, helping me find a better version of myself." <i>~&nbsp;Andy</i>
          </PartyPerson>


          <div className="h7 mobileOnly" />
          <div className="h12 desktopOnly" />

          <PartyPerson
            subtitle="Bridesmaid"
            title="Lexi Hofmann"
            image={justineLexi}
          >
            "Lexi and I first met in July 2011, when we traveled to Manhattan to look for an apartment to share. We had connected online via Sotheby’s Institute of Art, where we were both to begin coursework that fall. That day we found an apartment, plus a friendship so immediate that the property broker asked us if we had grown up together. We like to say we’ve known each other for thousands of years and have reincarnated together. Maybe we have." <i>~&nbsp;Justine</i>
          </PartyPerson>

          <div className="h7 mobileOnly" />
          <div className="h12 desktopOnly" />

          <PartyPerson
            subtitle="Groomsman"
            title="Luke Rocheleau"
            image={andyLuke}
            flipped
          >
            "Take all the things I aspire to be, shove them into a bear hug machine, and you get Luke. Hilarious, loyal, kind, and unbelievably giving, Luke is the best of everything you could ask for in a friend. I've known him for over a decade, and my only regret is that I haven't known&nbsp;him&nbsp;longer." <i>~&nbsp;Andy</i>
          </PartyPerson>
        </section>
      </Card>
    </Section>
  </Fragment>
);