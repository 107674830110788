import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";
// import CenteredColumn from '../centeredColumn';
import Text from "../text";

import styles from './schedule.module.scss';

const Timeslot = ({
  time,
  title,
  children,
}) => (
  <div className={styles.timeslot}>
    <div className={styles.timeCol}>
      {time}
    </div>
    <div className={styles.textCol}>
      <div className={styles.title}>
        {title}
      </div>
      <div className="h1" />
      <div className={styles.text}>
        {children}
      </div>
    </div>
  </div>
);

export default () => (
  <Fragment>
    <SectionHeader
      title="Schedule"
      bgColor="bgBurntYellow"
    />
    <Section>
      <Card>
        <CardTitle
          subTitle="When to do what"
          title="The Wedding Day Schedule"
        />
        <div className="h8 mobileOnly" />
        <div className="h10 desktopOnly" />
        <div className={styles.wrapper}>
          <Text>
            <Timeslot
              time="5:00pm"
              title="Guests Arrive"
            >
              Thanks for coming! Park your car, take a seat and get ready to enjoy the show.
            </Timeslot>
            <div className="h8 mobileOnly" />
            <div className="h7 desktopOnly" />
            <Timeslot
              time="5:30pm"
              title="Ceremony"
            >
              The lovebirds get hitched.
            </Timeslot>
            <div className="h8 mobileOnly" />
            <div className="h7 desktopOnly" />
            <Timeslot
              time="6:00pm"
              title="Cocktail Hour"
            >
              Grab a drink and some hors d'oeuvres, sign the guestbook, take some selfies with the sunflowers.
            </Timeslot>
            <div className="h8 mobileOnly" />
            <div className="h7 desktopOnly" />
            <Timeslot
              time="7:00pm"
              title="Dinner"
            >
              Head to your table and get ready to chow down. Dinner is served!
            </Timeslot>
            <div className="h8 mobileOnly" />
            <div className="h7 desktopOnly" />
            <Timeslot
              time="8:30pm"
              title="Party!"
            >
              Dance, drink, sing, photobooth. However you like to let loose &mdash; do it.
            </Timeslot>
          </Text>
        </div>
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
      </Card>
    </Section>
  </Fragment>
);
