import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";

export default () => (
  <Fragment>
    <SectionHeader
      title="Registry"
      bgColor="bgGoldenrod"
    />
    <Section>
      <Card>
        <CardTitle
          subTitle="Oh you shouldn't have"
          title="Zola Registry: FarmAloha"
        />
        <div className="h7 mobileOnly" />
        <div className="h7 desktopOnly" />
        <div className="buttonBlock">
          <a
            className="button"
            href="https://www.zola.com/registry/farmaloha"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Registry
          </a>
        </div>
      </Card>
    </Section>
  </Fragment>
);