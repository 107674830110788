import React from 'react';
import classNames from 'classnames';
import Img from './image';

import styles from './imageCard.module.scss';

export default ({ className, src }) => (
  <div
    className={classNames(styles.wrapper, className)}
  >
    <Img src={src} />
  </div>
);
