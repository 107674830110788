import React from "react"

import Layout from "../components/layout"
import Header from "../components/header";
import Marquee from "../components/marquee";
import AboutSection from "../components/sections/about";
import LocationSection from "../components/sections/location";
import TravelSection from "../components/sections/travel";
import RegistrySection from "../components/sections/registry";
import Schedule from "../components/sections/schedule";
import FAQsSection from "../components/sections/faqs";
import GalleryFooter from "../components/sections/galleryFooter";

import "../styles/global.scss";

const IndexPage = () => (
  <Layout>
    <div className="wrapper">
      <Header />
      <main>
        <Marquee />
        <LocationSection />
        <AboutSection />
        <Schedule />
        <TravelSection />
        <RegistrySection />
        <FAQsSection />
        <GalleryFooter />
      </main>
    </div>
  </Layout>
)

export default IndexPage
