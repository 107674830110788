import React, { Fragment } from 'react';
import SectionHeader from "../sectionHeader";
import Section from "../section";
import Card from "../card";
import CardTitle from "../cardTitle";
import Text from "../text";
import CenteredColumn from '../centeredColumn';

export default () => (
  <Fragment>
    <SectionHeader
      title="Travel"
      bgColor="bgSleepyGrey"
    />
    <Section>
      <Card>
        <CardTitle
          subTitle="Hotel"
          title="Holiday Inn Express & Suites"
        />
        <div className="h3" />

        <Text useClass="address">
          <address>
            3060 Spangler Rd.<br />
            West Middlesex, PA 16159<br />
            (724) 982 - 4600
          </address>
          <div className="h3 mobileOnly" />
          <div className="h5 desktopOnly" />
          <div className="buttonBlock">
            <a
              className="button"
              href="tel:724.982.4600"
            >
              Call
            </a>
            <a
              className="button"
              href="https://goo.gl/maps/sSLATWSSyPEKxRx97"
              target="_blank"
              rel="noopener noreferrer"
            >
              Map
            </a>
            <a
              className="button"
              href="https://www.hiexpress.com/redirect?path=hd&brandCode=EX&localeCode=en&regionCode=1&hotelCode=hgepa&_PMID=99801505&GPC=mcr&viewfullsite=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book
            </a>
          </div>
        </Text>
        <div className="h5 mobileOnly" />
        <div className="h10 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              The hotel is approximately 20 minutes from the farm. Shuttle service will be provided both to and from the wedding on the day of the ceremony.
              <br />
              <br />
              Planning to take the shuttle? More information will be available once you check in to your room.
              {/* Want to book an Airbnb instead? <a href="https://www.airbnb.com/s/Sharpsville--PA--United-States/homes" target="_blank" rel="noopener noreferrer">Try here.</a> */}
            </p>
          </Text>
        </CenteredColumn>
      </Card>

      <iframe
        className="map desktopOnly"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3002.5449862246496!2d-80.45616848458043!3d41.188090579282935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8833954c85ebaa85%3A0x9d8c70b42533a001!2sHoliday+Inn+Express+%26+Suites+Sharon-Hermitage!5e0!3m2!1sen!2sus!4v1556472876410!5m2!1sen!2sus"
        frameBorder="0"
        title="hotelMap"
        allowFullScreen
      />

      <Card>
        <CardTitle
          subTitle="Recommended Airport"
          title="Pittsburgh International Airport (PIT)"
        />
        <div className="h5 mobileOnly" />
        <div className="h3 desktopOnly" />
        <Text>
          Driving time to wedding area: <br className="mobileOnly" /> ~1 hour
        </Text>
        <div className="h4 mobileOnly" />
        <div className="h9 desktopOnly" />
        <div className="buttonBlock">
          <a
            className="button"
            href="https://goo.gl/maps/HiyoNRRogw4DQEEC6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Map
          </a>
        </div>
      </Card>

      <Card>
        <CardTitle
          subTitle="Alternative Airport"
          title="Cleveland International Airport (CLE)"
        />
        <div className="h5 mobileOnly" />
        <div className="h3 desktopOnly" />
        <Text>
          Driving time to wedding area: <br className="mobileOnly" /> ~1.5 hours
        </Text>
        <div className="h4 mobileOnly" />
        <div className="h9 desktopOnly" />
        <div className="buttonBlock">
          <a
            className="button"
            href="https://goo.gl/maps/j3pKupho67dPqvmi7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Map
          </a>
        </div>
      </Card>
    </Section>

    {/* <Section>
      <Card>
        <CardTitle title="Travel FAQs" id="travelFAQs" />
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              <strong>Which airport should I fly into?</strong><br/>
              Whichever one is less expensive, or whichever city interests
              you the most. The driving distance between the two options
              and the wedding area is relatively similar.
            </p>
          </Text>
        </CenteredColumn>
        <div className="h6 mobileOnly" />
        <div className="h5 desktopOnly" />
        <CenteredColumn>
          <Text>
            <p>
              <strong>Will I need to rent a car?</strong><br/>
              Probably! There’s a lot of open space around the area,
              and rideshare options are limited. We’d recommend
              renting a car from whichever airport you’re flying
              into — especially if you’re looking to explore.
            </p>
          </Text>
        </CenteredColumn>
      </Card>
    </Section> */}
  </Fragment>
);